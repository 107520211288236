import { Component } from '@angular/core';
import { Rates } from 'src/app/models/rates.model';
import { RatesService } from 'src/app/services/rates/rates.service';
import { MatTableDataSource } from '@angular/material/table';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { State } from 'src/app/models/enrol/state.model';
import { District } from 'src/app/models/enrol/district.model';
import { Brochure, UnderWriter } from 'src/app/models/Brochure.model';
import { LocationService } from 'src/app/services/location/location.service';
import { DownloadService } from 'src/app/services/download/download.service';
import { saveAs } from 'file-saver';
import { FiscalYearService } from 'src/app/services/fiscal-year.service';


@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss'],

})
export class RatesComponent {

  displayedColumns: string[] = ['activity', 'demographics', 'marketingPlan', 'rate'];

  rates: Rates[]=[];
  states: State[]=[];
  districts: District[] = [];
  selectedState: string = "";
  selectedDistrict: string="";
  stateName: string|undefined="";
  districtName: string|undefined="";
  coverageYear: number = 0;
  dataSource:MatTableDataSource<Rates>= new MatTableDataSource();
  brochureCode: Brochure[]=[];
  brochure: any;
  underwriters: UnderWriter = {
    insCode: '',
    insCo: '',
    state: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    logoLoc: ''
  };
		generalNotes:	string[]=[];
  constructor(private ratesService: RatesService, private enrolService: EnrolService,
    private locationService: LocationService, private downloads: DownloadService,
    private fiscalYearSerice:FiscalYearService) {
      this.coverageYear = this.fiscalYearSerice.getFiscalYearStart();
    }
  ngOnInit() {
    this.getCoverageStates();
  }

  download() {
    const link = this.getPDFLink(this.brochureCode[0].brochureCode)
    this.downloads
      .download(link)
      .subscribe(response => {
        const blob = new Blob([response], {type: 'application/pdf'});
        saveAs(blob, 'insurance-fileanme.pdf');
      });
}

  getRates(){
    this.rates = [];
    this.ratesService.getRates(this.stateName as string , Number(this.selectedDistrict)).subscribe({
      next: (result: any) => {
        result.body.models.forEach((item: { activity: string; demographics: string; marketingPlan: string; rate: number }) => {
          let data = {
              activity: item.activity,
              demographics: item.demographics,
              marketingPlan: item.marketingPlan,
              rate: item.rate

            } as Rates;

            this.rates.push(data);
            this.dataSource = new MatTableDataSource(this.rates);
        });
        this.generalNotes=[];
        result.body.generalNotes.forEach((item:string ) => {
          let data = item;
            this.generalNotes.push(data);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  getCoverageStates(){
    this.locationService.getCoverageStates().subscribe({
      next: (result: any) => {

        result.body.forEach((item: { name: string; state: string; }) => {
         let data = {
              name: item.name,
              state: item.state,
              postalAbbreviation: "",
              countryCode: "",
              capitalCity:"",
              standardAbbreviation:""
            } as State;

            this.states.push(data);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  getDistricts(){
    this.stateName = this.states.find(x=>x.state == this.selectedState)?.name;
    this.brochureCode = [];
    this.underwriters = Object.assign(new UnderWriter(), null);
    this.rates = [];
    this.dataSource = new MatTableDataSource();
    this.districtName = "";
    this.districts = [];
    this.generalNotes=[];

    this.locationService.getDistricts(this.selectedState).subscribe({
      next: (result: any) => {

        result.body.forEach((item: { policyNo: number; client: string; }) => {
          let data = {
              policyNo: item.policyNo,
              client: item.client
            } as District;

            this.districts.push(data);
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  getDistrictName(){
    this.districtName = this.districts.find(x=>x.policyNo.toString() == this.selectedDistrict)?.client;
    this.getRates();
    this.getBrochure();
  }

  getBrochure(){
    this.brochureCode = [];
    this.underwriters = Object.assign(new UnderWriter(), null);
    this.ratesService.getBrochure(Number(this.selectedDistrict)  ).subscribe({
      next: (result: any) => {

        result.body.brochureViewModels.forEach((item: { brochureCode : string; description : string }) => {
          let data = {
             brochureCode: item.brochureCode.replace(/(\r\n|\n|\r)/gm,""),
             description: item.description
            } as Brochure;

            this.brochureCode.push(data);

        });
        this.underwriters = Object.assign(new UnderWriter(), result.body.underwriterModel);


      },
      error: (err: any) => {
        console.log(err);
      }
    });

  }

  getPDFLink( brochureCode: string){
    let policyYear = this.fiscalYearSerice.getFiscalYearStart();
    let dt = new Date();
    let cacheTimeStamp =
      dt.getFullYear().toString() +
      ('0' + (dt.getMonth() + 1).toString()).slice(-2) +
      dt.getDate().toString() +
      dt.getHours().toString() +
      dt.getMinutes().toString();
    // let link ="http://www.hsri.com/enrollment/documents/"+policyYear+"/"+brochureCode+".pdf?v="+cacheTimeStamp;
    let link ="../Docs/Brochures/"+policyYear+"/"+brochureCode+".pdf?v="+cacheTimeStamp;
    return link;
  }



}
